@import '../../styles/vars.scss';

.footer-wrapper {
    @include flex;
    justify-content: center;
    width: 100vw;
    height: 106px;
    background-color: $black;
    box-sizing: border-box;
    overflow-x: hidden;

    .section {
        @include flex;
        justify-content: center;
        width: 100%;

        &.mobile {
            display: none;
        }

        .footer-link {
            font-size: 18px;
            color: $white;
            padding: 0 20px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1299px) {
    .footer-wrapper {
        height: fit-content;
        padding: 10px 0;
        flex-direction: column;

        .section {
            flex-direction: column;
            height: fit-content;
            align-items: flex-start;
            padding: 20px 10px;

            &.mobile {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-bottom: 1px solid $white;

                .footer-nav {
                    @include mediumFont;
                    font-size: 16px;
                    color: $white;
                    text-align: left;
                    margin-bottom: 20px;
                    padding: 0 20px;
                    cursor: pointer;
                }
            }

            &.margin {
                margin-top: 20px;
            }

            .footer-link {
                @include smallestHeading;
                color: $white;
                text-align: left;
                margin-bottom: 20px;
            }
        }
    }
}