@import "../../../styles/vars.scss";

.section-wrapper.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $black;
    height: 83vh;
    padding: 30px 60px;
    border-radius: 25px;

    h3 {
        color: $white;
    }

    .options-wrapper {
        @include flex;
        font-size: 18px;
        border: 1px solid $main-blue;
        border-radius: 6px;
        height: 45px;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 53vw;
    
        .option {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          height: 45px;
          border-left: 1px solid $main-blue;
          cursor: pointer;
          transition: 0.5s;
          @include regularFont;
          color: $white;
    
          &.selected {
            background-color: $main-blue;
            border-left: none;
            @include boldFont;
            color: $white;
            margin: 0;
            font-family: $fonts-bold;
            border-right: none;
            border-radius: 6px;
          }
          &.selected + .option {
            border-left: none;
          }
          &.first {
            border-left: none;
          }
          &.last {
            border-right: none;
          }
          &:hover {
            opacity: 0.8;
            transition: 0.5s;
          }
        }
      }

    .content-wrapper {
        display: flex;

        &.mobile {
          display: none;
        }

        .column {
            display: flex;
            flex-direction: column;
            padding: 0 15px;
            flex: 1;
            margin-top: 50px;
            
            .text {
                color: white;
                text-align: left;
                font-size: 20px;
                line-height: 27px;
            }

            .question-wrapper {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                margin-bottom: 10px;
                background-color: $black-blue;
                border-radius: 8px;
                padding: 15px 20px;
                white-space: pre-line;
                transition: 0.5s;

                &.selected {
                    border: 2px solid $main-blue;
                    font-family: $fonts-bold;
                    background-color: $dark-blue;
                    transition: 0.5s;
                }

                .question {
                    color: $white;
                    font-size: 18px;
                    font-family: $fonts-regular;
                    text-transform: uppercase;
                    text-align: left;
                }

                .arrow-icon {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
  .section-wrapper.info {
    height: fit-content;
    padding: 30px 40px;
    margin-bottom: 150px;

    h3 {
      font-size: 18px;
      margin-bottom: 40px;
    }

    .options-wrapper {
      &.desktop {
        display: none;
      }
    }

    .column {
      &.desktop {
        display: none;
      }
    }

    .content-wrapper {
      flex-direction: column;

      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }

      .section-wrapper {
        // margin: 0 20px;
        margin-bottom: 25px;

        .option {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          color: $white;
          background-color: $main-blue;
          border-left: none;
          @include boldFont;
          font-size: 16px;
          color: $white;
          margin: 0;
          border-right: none;
          border-radius: 6px;
        }

        .question-wrapper {
          padding: 15px 5px;

          .question {
            font-size: 14px;
          }

          .arrow-icon {
            transform: rotate(90deg);

            &.selected {
              transform: rotate(-90deg);
            }
          }
        }

        .column {
          padding: 0;
          margin-top: 10px;
          margin-bottom: 20px;

          .text {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .section-wrapper.info {
    height: fit-content;
    padding: 30px 20px;
  }
}