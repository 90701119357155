@import '../../styles/vars.scss';

    .single-card-wrapper {
      padding: 0;
      width: 422px;

      img {
        border-radius: 8px;
      }
      h4 {
        text-align: left;
        padding: 0 15px;
      }

      .flex-wrapper {
        margin-top: auto;
        padding: 10px 15px;
      }
      .icon {
        display: flex;
        align-self: center;
        justify-content: center;
      }

      .flex-wrapper {
        display: flex;
        align-items: center;
    
        &.wide {
          width: 100%;
          justify-content: space-between;
        }
        &.pointer {
          cursor: pointer;
        }
    
        h5 {
          @include link;
          text-decoration: none;
          color: $black;
          text-transform: uppercase;
        }
    
        .arrow-icon {
          margin-left: 10px;
          width: 26px;
        }
      }
    }
  