@import '../../../styles/vars.scss';

.section-wrapper.hero {
    // height: calc(100% - 10vh);
    // height: 89vh;
    min-height: 95vh;
    display: block;
    padding-bottom: 50px;
    background-image: url('../../../assets/images/hero-section-illustration-desktop.png');
    // background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    // margin-bottom: 50px;
    
    h1 {
       @include largestHeading;
    }
    h2 {
       @include largerHeading;
       text-transform: none;
    }
    img {
        width: 100vw;
        height: auto;
    }
}

@media screen and (max-width: 1299px) {
    .section-wrapper.hero {
        // height: calc(110vw - 100px);
        // padding: 75px 10px 0 10px;
        height: 46vh;
        height: 80vh;
        padding: 35px 10px 0 10px;

        h1 {
            @include largeHeadingMobile;
         }
         h2 {
            @include mediumHeadingMobile;
            font-family: $fonts-regular;
         }
    }
}

@media screen and (max-width: 768px) {
    .section-wrapper.hero {
        height: calc(110vw - 50px);
        min-height: 46vh;
        background-image: url('../../../assets/images/hero-section-illustration-mobile.png');
        background-size: 100%;
        // padding: 75px 10px 0 10px;
 
        // height: 52vh;
        padding: 15px 10px 0 10px;

        h1 {
            @include largeHeadingMobile;
         }
         h2 {
            @include mediumHeadingMobile;
            font-family: $fonts-regular;
         }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1700px) {
    .section-wrapper.hero {
        // height: calc(100% - 10vh);
        // height: calc(100vh - 63vh);
        padding-top: 150px;
        // height: 80vh;
    }
}