@import '../../../styles/vars.scss';

.section-wrapper.activity {
    background-color: $white;
    margin-bottom: 50px;
    position: relative;
    margin: 0;

    background-image: url('../../../assets/images/activity-section-background.jpg');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100%;

    .activity-wrapper {
        background-color: $white;
        border-radius: 20px;
        @include sectionShadow;
        padding: 10px 15px;
        margin: 0 80px;
        margin-bottom: 50px;
        margin-top: 100px;
        position: relative;
    }

    .activity-top {
        top: -20px;
        position: absolute;
        background-color: $white;
        border-radius: 20px;
        width: 100%;
        @include sectionShadow;
    }

    .cards-wrapper {
        width: 100%;
        display: flex;
        gap: 10px;
        margin-bottom: 50px;

        .single-card-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 10px;
            @include sectionShadow;
            flex: 1;
            width: 405px;
            height: 566px;
            padding: 10px 15px; 
            
            img {
                width: 100%;
                height: auto;
            }

            .text-wrapper {
                flex: 1;

                h4 {
                    text-transform: uppercase;
                }
                p {
                    text-transform: none;
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                @include sectionShadow;
                cursor: pointer;
                background-color: $main-blue;
                width: 45px;
                height: 45px;
                border-radius: 6px;
                display: flex;
                align-self: flex-end;
                padding: 10px 15px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .section-wrapper.activity {
        margin: 0;
        margin-bottom: 50px;
        margin-top: 50px;

        h3 {
            @include boldFont;
        }

        .activity-wrapper {
            margin: 0;
        }

        .cards-wrapper {
            flex-direction: column;

            .single-card-wrapper {
                height: fit-content;
                width: 100%;
                padding: 15px 10px;
                padding-bottom: 30px;
                margin-bottom: 10px;
    
                .text-wrapper {
                     h4 {
                        @include smallHeading;
                        font-size: 16px;
                     }
                     p {
                        @include smallestHeading;
                     }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .section-wrapper.activity {
        margin: 0 50px;
        margin-bottom: 100px;
        margin-top: 50px;

        h3 {
            @include boldFont;
        }

        .activity-wrapper {
            margin: 0;
        }

        .cards-wrapper {
            flex-direction: column;

            .single-card-wrapper {
                height: fit-content;
                width: 100%;
                padding: 15px 10px;
                padding-bottom: 30px;
                margin-bottom: 10px;
    
                .text-wrapper {
                     h4 {
                        @include smallHeading;
                        font-size: 16px;
                     }
                     p {
                        @include smallestHeading;
                     }
                }
            }
        }
    }
}