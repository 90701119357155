@import '../../styles/vars.scss';

.header-wrapper {
    @include flex;
    background-color: $white;
    border-radius: 8px;
    border-bottom: 5px solid $main-blue;
    padding:  0px 15px;
    margin: 15px auto;
    min-height: 80px;
    align-self: center;
    @include sectionShadow;
    position: fixed;
    min-width: 92vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 120;

    .mobile {
        display: none;
    }

    .header-section {
        @include flex;

        &.nav {
            flex: 2;
            justify-content: flex-end;

            .nav {
                @include flex;
                padding-left: 0;
                width: 100%;
    
                &.left {
                    justify-content: space-between;
                    width: calc(60vw - 60%);
                }
    
                li {
                    @include mediumFont;
                    font-size: 16px;
                    text-transform: uppercase;
                    list-style-type: none;
                    white-space: nowrap;
                    padding: 0 28px;
                    cursor: pointer;
                    transition: 0.5s;
    
                    &:hover {
                        color: $dark-blue;
                        transition: 0.5s;
                    }
                }
            }
        }
        &.logo {
            flex: 4;
            justify-content: flex-start;

            img {
                cursor: pointer;
                width: 200px;
                height: auto;
            }
        }
        &.language {
            flex: 0.4;
            justify-content: flex-end;

            .language-wrapper {

                span {
                    cursor: pointer;
                }

                .bold {
                    font-family: $fonts-bold;
                }
                .light {
                    font-family: $fonts-regular;
                }
            }
        }

    }
}

@media screen and (max-width: 767px) {
    .header-wrapper {
        // all: initial;
        width: 64%;
        min-height: 80px;
        // position: absolute;
        align-self: flex-start;

        .header-section {
            display: none;
        }

        .mobile {
            width: 100%;
            cursor: pointer;
            display: block;
            @include flex;
            justify-content: flex-start;
            // position: fixed;
            z-index: 100;
            margin: 10px 0;

            .background-wrapper {
                min-width: 100vw;
                height: 100vh;
                flex-grow: 1;
                background-color: rgba(255, 255, 255, 0.58);
                position: absolute;
                top: 0;
                bottom: 0;
                left: -4vw;
                z-index: 1;
            }

            .nav {
                margin-right: 30px;
                margin-left: 10px;
                border-radius: 8px;
                height: 42px;
                width: 40px;
                @include flex;
                justify-content: flex-start;

                &.opened {
                    all: initial;
                    background-color: $white;
                    position: absolute;
                    top: -15px;
                    left: -4vw;
                    @include flex;
                    flex-direction: column;
                    min-width: 100vw;
                    padding: 20px 0;
                    height: 522px;
                    @include bottomShadow;
                    border-bottom: 12px solid $main-blue;
                    border-radius: 0 0 20px 20px;
                    z-index: 100;

                    img {
                        align-self: flex-start;
                        cursor: pointer;
                        height: 25px;
                        margin-left: 15px;
                    }

                    .opened-nav {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;

                        li {
                            list-style: none;
                            @include smallHeading;
                            font-size: 16px;
                            padding-bottom: 30px;
                            cursor: pointer;
                            text-transform: uppercase;
                        }

                        .language-wrapper {
                            margin-top: 6vh;

                            .light {
                                font-family: $fonts-regular;
                            }
                            .bold {
                                font-family: $fonts-bold;
                            }
                        }
                    }
                }

                .nav-mobile {
                    width: 25px;
                }
            }

            .logo {
                // margin-left: calc(26% - 40px);
                width: 50vw;
                height: auto;
                @include flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .header-wrapper {
        // all: initial;
        width: 64%;
        min-height: 80px;
        // position: absolute;
        align-self: flex-start;

        .header-section {
            display: none;
        }

        .mobile {
            width: 100%;
            cursor: pointer;
            display: block;
            @include flex;
            justify-content: flex-start;
            // position: fixed;
            z-index: 100;
            margin: 10px 0;

            .background-wrapper {
                min-width: 100vw;
                height: 100vh;
                flex-grow: 1;
                background-color: rgba(255, 255, 255, 0.58);
                position: absolute;
                top: 0;
                bottom: 0;
                left: -4vw;
                z-index: 1;
            }

            .nav {
                margin-right: 30px;
                margin-left: 10px;
                border-radius: 8px;
                height: 42px;
                width: 40px;
                @include flex;
                justify-content: flex-start;

                &.opened {
                    all: initial;
                    background-color: $white;
                    position: absolute;
                    top: -15px;
                    left: -4vw;
                    @include flex;
                    flex-direction: column;
                    min-width: 100vw;
                    padding: 20px 0;
                    height: 522px;
                    @include bottomShadow;
                    border-bottom: 12px solid $main-blue;
                    border-radius: 0 0 20px 20px;
                    z-index: 100;

                    img {
                        align-self: flex-start;
                        cursor: pointer;
                        height: 25px;
                        margin-left: 15px;
                    }

                    .opened-nav {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        height: 100%;

                        li {
                            list-style: none;
                            @include smallHeading;
                            font-size: 16px;
                            padding-bottom: 30px;
                            cursor: pointer;
                            text-transform: uppercase;
                        }

                        .language-wrapper {
                            margin-top: 6vh;

                            .light {
                                font-family: $fonts-regular;
                            }
                            .bold {
                                font-family: $fonts-bold;
                            }
                        }
                    }
                }

                .nav-mobile {
                    width: 25px;
                }

                // img {
                //     width: 100px;
                //     height: auto;
                // }
            }

            .logo {
                margin-left: calc(29% - 40px);
                width: 30vw;
                height: auto;
                @include flex;
                justify-content: center;
            }
        }
    }
}

// @media screen and (max-width: 1299px) {
//     .header-wrapper {
//         .header-section {
//             .nav {
//                 width: calc(58vw - 50%);

//                 ul li {
//                     padding: 5px;
//                 }
//             }
            
//         }
//     }
// }