@import '../../styles/vars.scss';

.screen-wrapper.single-news {
    padding-top: 0;
    margin-bottom: 0;
    min-height: calc(100vh - 106px);
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 25px;
    margin-left: 80px;

    .column {
        flex: 1;

        h3 {
            @include largestHeading;
            text-transform: none;
            font-size: 30px;
            text-align: left;
        }
        p {
            text-align: left;
            font-size: 18px;
            text-transform: none;
        }

        &.padding {
            padding-top: 130px;
            // padding-bottom: 30px;
        }
        &.images {
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 93px;
        }
        &.mobile {
            display: none;
        }

        .top-button-wrapper {
            display: flex;
            cursor: pointer;

            .arrow-icon {
                transform: rotate(-180deg);
                margin-right: 10px;
            }
            .text {
                font-size: 18px;
            }
        }

        .images-wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            height: calc(100vh - 93px);

            &.unset {
                height: unset;
            }

            .news-image {
                object-fit: cover;
                cursor: pointer;

                &.full-width {
                    width: 100%;
                    height: 50%;
                    flex: 1;
                    flex-basis: 100%;
                }
                &.half-width {
                    width: 30%;
                    flex: 1;
                    flex-basis: 40%;
                }
            }

            .fill-screen-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 10;
                cursor: pointer;
            }
        }

    }
}

@media screen and (max-width: 1299px) {
    .screen-wrapper.single-news {
        flex-direction: column;
        margin-left: 0;
        padding: 0 40px;

        .column {

            &.desktop {
                display: none;
            }
            &.mobile {
                display: block;
            }
            &.images {
                padding-top: 0;
            }

            h3 {
                font-size: 20px;
            }
            p {
                font-size: 16px;
            }

            .top-button-wrapper {
                .text {
                    font-size: 16px;
                }
            }
            .news-image {
                width: 100%;
                margin-bottom: 30px;
            }
        }

    }
}