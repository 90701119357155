@import "../../../styles/vars.scss";

.section-wrapper.news {
  margin: 0 80px;
  height: 80vh;
  margin-top: 50px;

  .flex-wrapper {
    display: flex;
    align-items: center;

    &.wide {
      width: 100%;
      justify-content: space-between;
    }
    &.pointer {
      cursor: pointer;
    }
    &.mobile {
      display: none;
    }

    h5 {
      @include link;
      text-decoration: none;
      color: $black;
      text-transform: uppercase;
    }

    .arrow-icon {
      margin-left: 10px;
      width: 26px;
    }
  }

  .cards-wrapper {
    justify-content: space-between;
    .single-card-wrapper {
      padding: 0;

      img {
        border-radius: 8px;
      }
      h4 {
        text-align: left;
        padding: 0 15px;
      }

      .flex-wrapper {
        margin-top: auto;
        padding: 10px 15px;
      }
      .icon {
        display: flex;
        align-self: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .section-wrapper.news {
    height: fit-content;
    margin: 0 80px;
    margin-top: 50px;
    // margin: 20px;

    .flex-wrapper {
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 18px;
      }

      &.top {
        justify-content: center;
      }
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
        align-items: center;

        h5 {
          font-size: 16px;
        }
      }
    }

    .cards-wrapper {
      margin-bottom: 0;

      .single-card-wrapper {
        height: unset;
        margin-bottom: 20px;

        h4 {
          font-size: 18px;
        }

        .flex-wrapper {
          // margin-top: 10px;
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .section-wrapper.news {
    margin: 0 20px;
    margin-top: 50px;
    margin-bottom: 50px;

    .cards-wrapper {
      justify-content: center;
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1770px) {
  .section-wrapper.news {
    height: fit-content;
    margin: 0 60px;
    margin-top: 100px;
    margin-bottom: 150px;

    .flex-wrapper {
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 18px;
      }

      &.top {
        justify-content: center;
      }
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
        align-items: center;

        h5 {
          font-size: 16px;
        }
      }
    }

    .cards-wrapper {
      margin-bottom: 0;
      // justify-content: center;
      // padding: 0 50px;

      .single-card-wrapper {
        height: unset;
        margin-bottom: 20px;
        flex-basis: 45%;

        h4 {
          font-size: 18px;
          left: 0;
          width: 100%;
        }

        .flex-wrapper {
          // margin-top: 10px;
          p {
            font-size: 16px;
          }

          .icon {
            width: 35px;
            height: 35px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
