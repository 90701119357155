@import '../../styles/vars.scss';

.screen-wrapper.home {
    min-height: fit-content;
    padding-bottom: 150px;
    // background-image: url('../../assets/images/hero-section-illustration-desktop.png');
    // background-size: 100%;
    // background-repeat: no-repeat;
    background-position: center top;
    width: 100vw;
    overflow-x: hidden;
    // padding-top: 80px;
}

@media screen and (max-width: 1700px) {
    .screen-wrapper.home {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 1299px) {
    .screen-wrapper.home {
        background-position: center 2%;
        object-fit: cover;
        background-size: 200%;
        padding-top: 120px;
        padding-bottom: 50px;
    }
}

@media screen and (max-width: 768px) {
    .screen-wrapper.home {
        background-position: center 1%;
        // background-position: center calc(10vh - 5px);
        object-fit: cover;
        background-size: 200%;
        padding-top: 120px;
    }
}

// @media screen and (min-width: 1024px) and (max-width: 1700px) {
//     .screen-wrapper.home {
//         background-position: center 0%;
//         // background-position: center calc(10vh - 5px);
//         object-fit: cover;
//         background-size: 200%;
//         padding-top: 120px;
//     }
// }