@import '../../styles/vars.scss';

.screen-wrapper.news {
    // height: calc(100vh - 106px);
    min-height: fit-content;
    margin: 0 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    // .top-button-wrapper {
    //   display: none;
    // }

    h3 {
      @include mediumHeading;
      margin: 0;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;
      width: 50vw;
      margin-bottom: 50px;
    }

    .top-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      // align-self: flex-start;
      left: 0;
      cursor: pointer;

      .arrow-icon {
          transform: rotate(-180deg);
          margin-right: 10px;
      }
      .text {
          font-size: 18px;
      }
  }

    .cards-wrapper {
      justify-content: space-between;

      .single-card-wrapper {
        margin-bottom: 40px;
        width: 422px;
      }
    }
}

// @media screen and (max-width: 909px) {
//     .screen-wrapper.news {
//         padding-left: 45px;
//         padding-right: 45px;
//         align-items: center;

//         // .cards-wrapper {
//         //     justify-content: center;

//         //     .single-card-wrapper {
//         //         margin-bottom: 15px;
//         //     }
//         // }

//         .cards-wrapper {
//             justify-content: space-between;
//             .single-card-wrapper {
//               padding: 0;
        
//               img {
//                 border-radius: 8px;
//               }
//               h4 {
//                 text-align: left;
//                 padding: 0 15px;
//                 font-size: 18px;
//               }
        
//               .flex-wrapper {
//                 margin-top: auto;
//                 padding: 10px 15px;

//                 &.wide {
//                   margin-top: none;
//                 }
//               }
//               .icon {
//                 display: flex;
//                 align-self: center;
//                 justify-content: center;
//               }
//             }
//           }
//     }
// }

@media screen and (max-width: 1300px) {
  .screen-wrapper.news {
    padding-left: 45px;
    padding-right: 45px;
    align-items: flex-start;
    margin: 0;

    .flex {
      h3 {
        @include smallHeading;
        font-family: $fonts-bold;
        margin-top: 50px;
      }
    }

    .top-button-wrapper {
      display: flex;
      cursor: pointer;
      margin-bottom: 20px;

      .arrow-icon {
          transform: rotate(-180deg);
          margin-right: 10px;
      }
      .text {
          font-size: 18px;
      }
  }

    .cards-wrapper {
        // justify-content: flex-start;
        // justify-content: center;


        // .single-card-wrapper {
        //     margin-bottom: 15px;
        //     flex-basis: 40%;
        // }
    }

    .cards-wrapper {
      flex-direction: column;

      .single-card-wrapper {
          height: fit-content;
          width: 100%;
          padding: 15px 10px;
          padding-bottom: 10px;
          margin-bottom: 10px;

          h4 {
            @include smallHeading;
            font-size: 18px;
         }
         p {
            @include smallestHeading;
         }
      }
  }
}
}

@media screen and (max-width: 767px) {
  .screen-wrapper.news {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 100px;
    margin: 0;
    align-items: flex-start;

    .flex {
      width: 55.5vw;
      h3 {
        margin-top: 80px;
      }
    }
  }
}