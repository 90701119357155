@import '../../styles/vars.scss';

.single-activity-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 75vh;
    justify-content: center;
    align-items: stretch;
    // margin: 0 auto;
    padding: 10px 70px;
    padding-left: 0;

    .top-button-wrapper {
        display: flex;
        cursor: pointer;
        margin-bottom: 20px;

        .arrow-icon {
            transform: rotate(-180deg);
            margin-right: 10px;
        }
        .text {
            font-size: 18px;
        }
    }

    .columns-wrapper {
        display: flex;
        align-items: stretch;
        height: 60vh;
    }

    .column {
        flex: 1;

        &.dark {
            @include sectionShadow;
            background-color: $black;
            border-radius: 20px 0 0 20px;
            padding: 20px 45px;
        }
        &.center {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        &.padding {
            padding-top: 130px;
            // padding-bottom: 30px;
        }
        &.image {
            @include sectionShadow;
            border-radius: 0 20px 20px 0;
            width: 100%;

            img {
                height: 100%;
                // width: 43vw;
                min-width: 44vw;
                flex: 1;
                border-radius: 0 20px 20px 0;
            }
        }
        &.mobile {
            display: none;
        }
        
        h3 {
            @include largestHeading;
            font-size: 30px;
            text-transform: none;
            color: $white;
            text-align: left;
        }
        h5 {
            @include boldFont;
            text-transform: none;
            color: $white;
            margin: 0;
        }
        p {
            text-align: left;
            font-size: 18px;
            color: $white;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 40px;
        }

        .flex {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            img {
             margin-right: 10px;
            }
        }

    }
}

// @media screen and (max-width: 1300px) {
//     .single-activity-wrapper {
//         min-height: fit-content;
//         overflow: visible;
//         .columns-wrapper {
//             flex-direction: column;
//         }
//     }
// }

@media screen and (max-width: 1300px) {
    .single-activity-wrapper {
        min-height: fit-content;
        overflow: visible;
        flex-direction: column;
        padding: 0;
        margin: 0;
        margin-top: 30px;
        margin-bottom: 0;
        background-image: none;
        padding-bottom: 0;
        height: fit-content;

        .top-button-wrapper {
            padding: 0 50px;
            .text {
                @include smallestHeading;
            }
        }

        .columns-wrapper {
            flex-direction: column;
        }

        .column {
            &.dark {
                padding: 10px 50px;
                border-radius: 14px 14px 0 0;
            }

            &.image {

                img {
                    height: 100%;
                    width: 100vw;
                    flex: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .single-activity-wrapper {
        min-height: fit-content;
        flex-direction: column;
        padding: 0;
        margin: 0;
        margin-top: 30px;
        margin-bottom: 30px;
        background-image: none;
        padding-bottom: 0;

        .top-button-wrapper {
            padding: 0 20px;
            .text {
                @include smallestHeading;
            }
        }

        .column {
            &.dark {
                padding: 10px 20px;
                border-radius: 14px 14px 0 0;
            }
        }

        .columns-wrapper {
            flex-direction: column;

            .column {

                &.dark {
                    padding: 10px 20px;

                    h3 {
                        @include boldFont;
                        text-transform: none;
                        color: $white;
                    }
                    h5 {
                        @include smallHeadingMobile;
                        color: $white;
                    }
                    p {
                        @include smallestHeading;
                        color: $white;
                    }
                }
            }
        }
    }
}