@import './styles/vars.scss';

.App {
  text-align: center;
  color: #04010f;
  box-sizing: border-box;
  // height: 100vh;
  font-family: $fonts-regular;
  position: relative;
  // justify-content: space-between;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div {
    color: #04010f;
  }

  h3 {
    @include mediumHeading;
  }
  h4 {
    @include largeHeading;
  }
  p {
    @include regularFont;
  }

  .screen-wrapper {
    height: 100%;
    padding-top: 160px;
  }

  .cards-wrapper {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    flex-grow: 1;

    .single-card-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 10px;
        @include sectionShadow;
        // flex: 1;
        width: 405px;
        height: 566px;
        padding: 10px 15px; 

        .text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;

          h4 {
            text-align: center;
          }
        }
        
        img {
            width: 100%;
            height: auto;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            @include sectionShadow;
            cursor: pointer;
            background-color: $main-blue;
            width: 45px;
            height: 45px;
            border-radius: 6px;
            display: flex;
            align-self: flex-end;
            padding: 10px 15px;

            img {
                width: 35px;
                height: 35px;
            }
        }
    }
}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
