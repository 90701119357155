@import "../../../styles/vars.scss";

.section-wrapper.contact {
    display: flex;
    height: 65vh;
    margin: 0 82px;
    margin-top: 100px;
    background-image: url('../../../assets/images/section-3.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    z-index: 0;


    .column {
        flex: 1;
        @include sectionShadow;
        border-radius: 20px;
        height: 65vh;
        z-index: 100;
        background-color: white;
        // height: 400px;

        &.padding {
            padding: 5px 30px;
        }
        &.right {
            border-radius: 0 20px 20px 0;
        }

        h3 {
            text-align: left;
            margin-top: 100px;
        }
        iframe {
            border-radius: 8px 0 8px 0;
        }

        .rows-wrapper {
            display: flex;
            flex-direction: column;

            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 20px;
                margin-bottom: 10px;
                border-radius: 8px;
                @include bottomShadow;

                .left {
                    display: flex;
                    align-items: center;

                    img {
                        width: 25px;
                        height: auto;
                    }

                    .text {
                        color: $main-blue;
                        margin-left: 5px;
                    }
                }

                .right {
                    .text {
                        @include tableRow;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    .section-wrapper.contact {
        flex-direction: column;
        padding: 0 55px;
        margin: 0;
        margin-bottom: 100px;
        background-image: none;
        padding-bottom: 0;
        height: fit-content;

        .column {
            h3 {
                font-size: 20px;
                margin-top: 10px;
                text-align: center;
            }

            &.left {
                order: 2;
            }
            &.right {
                order: 1;
            }
            &.padding {
                padding: 5px 0;
                box-shadow: none;
            }

            iframe {
                min-height: 30vh;
            }

            .rows-wrapper {
                .row {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 30px 10px;
    
                    .left {
                        // padding-top: 15px;
                        margin-bottom: 10px;
                    }
                    .right {
                        .text {
                            font-size: 16px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .section-wrapper.contact {
        flex-direction: column;
        padding: 0 20px;
        margin: 0;
        margin-top: 50px;
        margin-bottom: 30px;
        background-image: none;
        padding-bottom: 0;
        height: fit-content;
    }
}