@import "../../../styles/vars.scss";

.section-wrapper.about {
  display: flex;
  justify-content: center;

  background-color: $white;
  border-radius: 20px;
  @include sectionShadow;
  padding: 0;
  padding-left: 25px;
  margin: 0 75px;
  margin-top: 80px;

  // height: 80vh;
  height: fit-content;
  border-radius: 25px;

  .text-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    flex: 1;

    h3 {
      @include mediumHeading;
      margin-bottom: 30px;
    }

    p {
      @include regularFont;
      text-transform: none;
      text-align: left;
      margin-right: 20px;
      margin-bottom: 7px;
    }
  }

  .timeline-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: $black;
    border-radius: 0 20px 20px 0;
    @include sectionShadow;
    padding: 25px 20px;
    flex: 1;

    .line {
      // order: 2;
      height: 0px;
      border-top: 2px solid $white;
      border-right: none;
      width: 80px;
      // width: 100%;

      &.horizontal {
        height: 100%;
        width: 2px;
        position: absolute;
        bottom: 27%;
        left: 42px;
        top: 0;
        z-index: 0;
        border-right: 2px solid $white;
      }
    }

    .timeline-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      flex: 1;
      margin-bottom: 15px;
      width: 100%;

      .mobile-date {
        display: block;
        margin-bottom: 5px;
        @include mediumFont;
        font-size: 20px;
        color: $white;
      }

      .text-date-wrapper {
        order: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
      }

      .text-wrapper {
        background-color: $dark-blue;
        // height: 110px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        white-space: pre-wrap;
        z-index: 2;
        order: 3;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;

        h4 {
          @include tableRow;
          color: $white;
          font-family: $fonts-bold;
          text-align: left;
          margin: 10px 0 0 0;
        }

        .date {
          @include regularFont;
          text-transform: none;
          color: $white;
          width: 100%;
          margin: 0;
          text-align: left;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: space-between;

          img {
            cursor: pointer;
            margin-left: 15px;
          }
        }

      }

      .circle {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: $main-blue;
        border: 2px solid $black;
        box-shadow: 0 0 0 2px $white;
        z-index: 1;
      }
      .icon {
        height: 45px;
        width: 45px;
        z-index: 1;
      }
      p {
        font-family: $fonts-medium;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .section-wrapper.about {
    height: fit-content;
    // padding: 30px 50px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    margin: 0;

    h3 {
      @include boldFont;
      color: white;
      margin-bottom: 25px;
    }

    .text-section-wrapper {
      padding: 10px 50px;
    }

    .timeline-wrapper {
        flex-direction: column;
        width: 100%;
        padding: 25px 50px;
        border-radius: 0 0 14px 14px;

        .desktop-date {
            display: none;
        }

        .line {
            order: 2;
            width: 80px;
            height: 0px;
            border-top: 2px solid $white;
            border-right: none;

            &.horizontal {
              left: 70px;
            }
          }

          .circle {
            order: 1;
            min-height: 25px;
            min-width: 25px;
          }

          .timeline-section {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .text-date-wrapper {
                order: 3;
                display: flex;
                flex-direction: column;
                align-items: center;

                .mobile-date {
                    display: block;
                    margin-bottom: 5px;
                    @include mediumFont;
                    font-size: 16px;
                    color: $white;
                }
            }
            
            .text-wrapper {
                order: 3;

                h4 {
                    @include smallHeadingMobile;
                    text-transform: none;
                    color: $white;
                }

                .date {
                  @include smallestHeading;
                  color: $white;
                }
            }
          }
    }
  }
}

@media screen and (max-width: 767px) {
  .section-wrapper.about {
    padding: 30px 0;

    .text-section-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 15px;
      flex: 1;
  
      h3 {
        @include boldFont;
        margin-bottom: 5px;
      }
  
      p {
        @include smallestHeading;
        text-transform: none;
        text-align: left;
        margin-right: 20px;
      }
    }

    .timeline-wrapper {
      padding: 10px 15px;
      border-radius: 0 0 14px 14px;

      .line {

        &.horizontal {
          left: 36px;
        }
      }
    }
  }
}
