@import '../../styles/vars.scss';

.screen-wrapper.single-activity {
    padding-top: 85px;
    margin-bottom: 0;
    min-height: calc(100vh - 106px);
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 25px;
    margin-left: 80px;
}


@media screen and (max-width: 1300px) {
    .screen-wrapper.single-activity {
        margin: 0;
        padding: 0;
        padding-top: 120px;
        padding-bottom: 40vh;
        height: fit-content;
    }
}

@media screen and (max-width: 767px) {
    .screen-wrapper.single-activity {
        margin: 0;
        padding: 0;
        padding-top: 120px;
        padding-bottom: calc(100vw - 7vh);
        height: fit-content;
    }
}